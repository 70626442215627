import React from "react";
import { Helmet } from "react-helmet-async";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and conditions</title>
        <meta
          name="description"
          content="We are a non-profit organization focused on celebrating and empowering young girls and women in technology across Africa."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Terms and conditions" />
        <meta
          property="og:description"
          content="We are a non-profit organization focused on celebrating and empowering young girls and women in technology across Africa."
        />
        <meta name="twitter:title" content="Terms and conditions" />
        <meta
          name="twitter:description"
          content="We are a non-profit organization focused on celebrating and empowering young girls and women in technology across Africa."
        />
      </Helmet>
      <Header />
      <section
        className="container mx-auto px-4 pt-[100px] md:pt-[180px]"
        aria-labelledby="terms-heading"
      >
        <div className="container mx-auto text-center ">
          <h1
            className="text-primary-color font-weight-bolder md:text-4xl text-2xl my-2"
            id="terms-heading"
          >
            TERMS AND CONDITIONS
          </h1>
          <small>
            Please, carefully read the terms below. For the purpose of this
            document, She Code Africa will be represented with its abbreviation
            (SCA).
          </small>
        </div>
        <div className="w-11/12 mx-auto my-10">
          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">1.0</span> General
            </h3>
            <div>
              <p>
                <span className="sub_ol">1.1</span>
                <span>
                  {" "}
                  By proceeding with SCA on any matter, it is taken that you
                  have read through, understood and given your consent to be
                  bound by all terms set below.
                </span>
              </p>
              <p>
                <span className="sub_ol">1.2</span>
                <span>
                  {" "}
                  These terms shall from time to time be reviewed subject to
                  accruing issues and circumstances which may come into being.
                </span>
              </p>
              <p>
                <span className="sub_ol">1.3</span>
                <span>
                  {" "}
                  SCA reserves the right to do with these terms as it wishes.
                </span>
              </p>
            </div>
          </div>
          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">2.0</span> Specific Terms
            </h3>
            <div>
              <p>
                <span className="sub_ol">2.1</span>
                <span>
                  “Donation” means any such money as may be given in
                  contribution to SCA to further its objectives.
                </span>
              </p>
              <p>
                <span className="sub_ol">2.2</span>
                <span>
                  “Legal Member” means any young girl or woman who may at
                  anytime become a part of SCA network or team.
                </span>
              </p>
              <p>
                <span className="sub_ol">2.3</span>
                <span>
                  {" "}
                  “Volunteer” means any young girl or woman who may upon
                  successful application begin to offer her services to SCA at
                  no binding fees.
                </span>
              </p>
              <p>
                <span className="sub_ol">2.4</span>
                <span>
                  {" "}
                  “Partner/Donor” means any legal entity that may at anytime
                  come in contact with SCA for an official purpose.
                </span>
              </p>
              <p>
                <span className="sub_ol">2.5</span>
                <span>
                  {" "}
                  “Agreement” means any legally binding arrangement between SCA
                  and a partner/donor for a course.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">3.0</span> Donation
            </h3>
            <div>
              <p>
                <span className="sub_ol">3.1</span>
                <span>
                  Donations do not form a contract for future or successive
                  legal relationships or transactions.
                </span>
              </p>
              <p>
                <span className="sub_ol">3.2</span>
                <span>
                  SCA reserves the right to use every donation at its
                  discretion, subject to its organizational objectives.
                </span>
              </p>
              <p>
                <span className="sub_ol">3.3</span>
                <span>
                  Any personal details received by SCA pursuant to any
                  charitable donation, shall be kept confidential and private.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">4.0</span> Legal Member
            </h3>
            <div>
              <p>
                <span className="sub_ol">4.1</span>
                <span>
                  A legal member is any lady who via SCA’s membership
                  application form puts in an application, and upon review of
                  such application is accepted.
                </span>
              </p>
              <p>
                <span className="sub_ol">4.2</span>
                <span>
                  A legal member shall for the purpose of her membership to any
                  of SCA’s platforms, projects or programmes, be subject to all
                  SCA’s rules and regulations until at any such time when her
                  membership may terminate.
                </span>
              </p>
              <p>
                <span className="sub_ol">4.3</span>
                <span>
                  Where a legal member is assigned a position, she shall subject
                  to SCA’s guidelines for such position carry out her functions
                  for the sole purpose of furthering SCA’s legal objectives.
                </span>
              </p>
              <p>
                <span className="sub_ol">4.4</span>
                <span>
                  {" "}
                  A legal member may at anytime communicate in writing to SCA of
                  her decision to have her membership terminated.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">5.0</span> Volunteer
            </h3>
            <div>
              <p>
                <span className="sub_ol">5.1</span>
                <span>
                  A person shall be deemed a volunteer upon a written
                  application and a written confirmation of satisfaction with
                  such application by SCA.
                </span>
              </p>
              <p>
                <span className="sub_ol">5.2</span>
                <span> SCA volunteers are not entitled to salaries.</span>
              </p>
              <p>
                <span className="sub_ol">5.3</span>
                <span>
                  A volunteer’s offer of engagement may be reviewed upon such
                  time as may be agreed by SCA and the volunteer.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">6.0</span>Partner/Donor
            </h3>
            <div>
              <p>
                <span className="sub_ol">6.1</span>
                <span>
                  Partnership between SCA and any legal entity shall be deemed
                  as commenced upon a written agreement executed by both
                  parties.
                </span>
              </p>
              <p>
                <span className="sub_ol">6.2</span>
                <span>
                  {" "}
                  A partner/donor who at anytime comes into a legal relationship
                  with SCA, shall be bound by all SCA’s Terms and Conditions.
                </span>
              </p>
              <p>
                <span className="sub_ol">6.3</span>
                <span>
                  All partners/donors are to the extent of the agreements
                  entered into with SCA, bound.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">7.0</span> Agreement
            </h3>
            <div>
              <p>
                <span className="sub_ol">7.1</span>
                <span>
                  All formal partnerships between SCA and other legal entities
                  shall be deemed to have commenced ONLY by a written agreement
                  executed by representatives or agents of each party.
                </span>
              </p>
              <p>
                <span className="sub_ol">7.2</span>
                <span>
                  Each party to an agreement shall reserve the right to have in
                  its possession a copy of the executed agreement.
                </span>
              </p>
              <p>
                <span className="sub_ol">7.3</span>
                <span>
                  Disputes arising from any agreement between SCA and other
                  legal entities shall be settled amicably.
                </span>
              </p>
              <p>
                <span className="sub_ol">7.4</span>
                <span>
                  Where amicable settlement fails, the matter shall be settled
                  by an independent arbitrator who shall be chosen by the
                  parties in dispute.
                </span>
              </p>
              <p>
                <span className="sub_ol">7.5</span>
                <span>
                  Where settlement by an independent arbitrator fails, the
                  matter shall be settled by a court in the State where such
                  issue(s) arose.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">8.0</span> Confidentiality
            </h3>
            <div>
              <p>
                <span className="sub_ol">8.1</span>
                <span>
                  SCA holds in high esteem the information of its legal members
                  and partners/donors, and is committed to preserving same even
                  in the event of a termination of membership or partnership.
                </span>
              </p>
              <p>
                <span className="sub_ol">8.2</span>
                <span>
                  All information collected from legal members or
                  partners/donors shall be handled with privacy, except where a
                  legal member or partner/donor says otherwise.
                </span>
              </p>
              <p>
                <span className="sub_ol">8.3</span>
                <span>
                  {" "}
                  Where the information of a legal member or partner/donor is
                  required to be public, SCA will specifically request the
                  approval of the legal member or partner/donor before any such
                  information is made public.
                </span>
              </p>
              <p>
                <span className="sub_ol">8.4</span>
                <span>
                  In a case where a legal member’s or partner’s/donor’s
                  information is already public, SCA is not bound to request an
                  approval before further making public such information.
                </span>
              </p>
            </div>
          </div>

          <div className="donation-faq">
            <h3 className="text-primary-color font-weight-bolder text-2xl">
              <span className="sub_ol">9.0</span>Limitation of Liability
            </h3>
            <div>
              <p>
                <span className="sub_ol">9.1</span>
                <span>
                  Where a legal member is in contact with any SCA partner/donor
                  for a project that will solely benefit her, SCA is not bound
                  to indemnify the legal member in the event anything goes
                  wrong.
                </span>
              </p>
              <p>
                <span className="sub_ol">9.2</span>
                <span>
                  Where a legal member is acting on behalf of SCA and out of
                  negligence causes any damage, SCA will not be bound to
                  indemnify the member.
                </span>
              </p>
              <p>
                <span className="sub_ol">9.3</span>
                <span>
                  {" "}
                  Nothing in this agreement shall exclude or restrict SCA’s
                  liability for death or personal injury resulting from the
                  negligence of SCA or its agents.
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
